import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'

const buildTicketPayload = (ticket) => {
  const ticketAttributes = _.pick(ticket, [
    'id',
    'title',
    'userId',
    'assigneeId',
    'dueDate',
    'description',
    'origin',
    'tagIds',
    'tagsAttributes',
    'departmentId',
  ])

  if (ticket.tags) {
    ticketAttributes.tagIds = ticket.tags.filter(tag => !tag.isNew).map(tag => tag.id)
    ticketAttributes.tagsAttributes = ticket.tags.filter(tag => tag.isNew).map(tag => ({
      name: tag.name,
    }))
  }

  return ticketAttributes
}

const buildMessagePayload = message => _.pick(message, [
  'id',
  'content',
])

const buildCommentPayload = comment => _.pick(comment, [
  'id',
  'content',
  'private',
])

const buildTagPayload = tag => _.pick(tag, ['name'])

const buildDepartmentPayload = department => _.pick(department, ['name', 'emojiCode', 'email'])

export type SuggestedMessageType = {
  id: string
  content: string
}

const adminTicketing = {
  tickets: {
    ...generateCrudRoutes('/api/admin/ticketing/tickets', 'ticket', {}, buildTicketPayload),

    assign(ticketId, assigneeId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/assign`, { assigneeId })
    },

    close(ticketId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/close`)
    },

    reopen(ticketId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/reopen`)
    },

    start(ticketId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/start`)
    },

    reset(ticketId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/reset`)
    },

    counts() {
      return Axios.get('/api/admin/ticketing/tickets/counts')
    },

    suggestedMessage: {
      sendReply(ticketId) {
        return Axios.post(`/api/ticketing/tickets/${ticketId}/current_suggested_message/send_reply`)
      },

      editReply(ticketId) {
        return Axios.put(`/api/ticketing/tickets/${ticketId}/current_suggested_message/edit_reply`)
      },
    },

    updateEmployee(ticketId, employeeId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/update_employee`, { employeeId })
    },
  },
  messages: {
    fetchAll(ticketId, opts) {
      const options = { page: 1, ...opts }

      return Axios.get(searchURI(`/api/admin/ticketing/tickets/${ticketId}/messages`, options))
    },

    create(ticketId, message) {
      return Axios.post(`/api/admin/ticketing/tickets/${ticketId}/messages`, { message: buildMessagePayload(message) })
    },
  },
  comments: {
    create(ticketId, comment) {
      return Axios.post(`/api/admin/ticketing/tickets/${ticketId}/comments`, { comment: buildCommentPayload(comment) })
    },
  },
  ticketActivities: {
    fetchAll(ticketId) {
      return Axios.get(`/api/admin/ticketing/tickets/${ticketId}/ticket_activities`)
    },
  },
  suggestedMessages: {
    pdfFile: {
      update(suggestedMessageId, params) {
        return Axios.put(`/api/admin/ticketing/suggested_messages/${suggestedMessageId}/pdf_file`, params)
      },
    },
  },
  tags: generateCrudRoutes('/api/admin/ticketing/tags', 'tag', {}, buildTagPayload),
  departments: generateCrudRoutes('/api/admin/ticketing/departments', 'department', {}, buildDepartmentPayload),
  zoho: {
    updateSupportEmailAddress(email) {
      return Axios.patch('/api/admin/ticketing/zoho/update_support_email_address', { email })
    },
  },
  integrationLogs: {
    fetchAll(opts) {
      const options = { page: 1, ...opts }

      return Axios.get(searchURI('/api/admin/ticketing/integration_logs', options))
    },
  },
  agents: generateCrudRoutes('/api/admin/ticketing/agents', 'agent'),
}

export default adminTicketing
